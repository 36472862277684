import React from 'react'
//Gatsby
import { Link } from "gatsby"
//Material UI
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
//Components


const useStyles = makeStyles((theme) => ({
	navItem: {
		fontFamily: "dinpro-light",
		margin: "2rem",
		padding: "0.5rem",
		textTransform: "uppercase",
		textDecoration: "none",
		fontSize: "1rem",
		color: "#000",
	},
	navLinkColumn: {
		display: "flex",
		flexDirection: "column",
	},
}));

const DrawerMenu = ({ isOpen, toggleDrawer }) => {
	const classes = useStyles();
	const [activItem, setActivItem] = React.useState('');
	React.useEffect(() => {
		setActivItem(window.location.pathname)
	}, [])
	return (
		<Drawer anchor="right" open={isOpen} onClose={() => toggleDrawer()}>
			<div className={classes.navLinkColumn}>
				<Link
					to="/"
					// onClick={() => selectActivItem("/")}
					className={classes.navItem}
				>
					<div style={{ marginBottom: "5px" }}>
						Le Cabinet
					</div>
					<div className={activItem === "/" ? "activ-underline" : "underline"}></div>
				</Link>
				<Link
					to="/domaines"
					// onClick={() => selectActivItem("item2")}
					className={classes.navItem}
				>
					<div style={{ marginBottom: "5px" }}>
						Domaines d'intervention
					</div>
					<div className={activItem === "/domaines" ? "activ-underline" : "underline"}></div>
				</Link>
				<Link
					to="/equipe"
					// onClick={() => selectActivItem("item3")}
					className={classes.navItem}
				>
					<div style={{ marginBottom: "5px" }}>
						équipe
					</div>
					<div className={activItem === "/equipe" ? "activ-underline" : "underline"}></div>
				</Link>
				<Link
					to="/contact"
					// onClick={() => selectActivItem("item5")}
					className={classes.navItem}
				>
					<div style={{ marginBottom: "5px" }}>
						Contact
					</div>
					<div className={activItem === "/contact" ? "activ-underline" : "underline"}></div>
				</Link>
			</div>
		</Drawer>
	)
}

export default DrawerMenu