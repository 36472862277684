import { Link } from "gatsby"
// import PropTypes from "prop-types"
import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Img from "gatsby-image"

const useStyles = makeStyles((theme) => ({
  menuButton: {
    color: "#d7af24",
    height: "fit-content",
		[theme.breakpoints.up("md")]: {
			display: "none",
		},
  },
  navItem: {
    fontFamily: "dinpro-light",
    padding: "0.5rem",
    textTransform: "uppercase",
    textDecoration: "none",
    fontSize: "1rem",
    color: "#000",
  },
	navLinkRow: {
		width: "100%",
		display: "flex",
		flexDirection: "row",
		justifyContent: "flex-end",
		alignItems: "flex-end",
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
}))

const Header = ({ image, toggleDrawer }) => {
  const classes = useStyles();
  const [activItem, setActivItem] = React.useState('');
  React.useEffect(() => {
    setActivItem(window.location.pathname)
	}, [])
  // const selectActivItem = (item) => {
  //   setActivItem(item)
  // }

  return (
  <header id="header">
    <div className="logo-wrapper">
      <Link to="/" >
        <Img fluid={image} />
      </Link>
    </div>
    <IconButton id="menu-icon" className={classes.menuButton} edge="end" onClick={() => toggleDrawer()} color="inherit" aria-label="menu">
			<MenuIcon />
		</IconButton>
    <div id="navbar-wrapper" className={classes.navLinkRow}>
      <Link
            to="/"
            className={classes.navItem}
          >
          <div style={{marginBottom: "5px"}}>
            Le Cabinet
          </div>
          <div className={activItem === "/" ? "activ-underline" : "underline"}></div>
      </Link>
      <Link
            to="/domaines"
            className={classes.navItem}
          >
            <div style={{marginBottom: "5px"}}>
              Domaines d'intervention
            </div>
          <div className={activItem === "/domaines" ? "activ-underline" : "underline"}></div>
      </Link>
      <Link
            to="/equipe"
            className={classes.navItem}
          >
            <div style={{marginBottom: "5px"}}>
              équipe
            </div>
          <div className={activItem === "/equipe" ? "activ-underline" : "underline"}></div>
      </Link>
      {/* <Link
            to="/actualites"
            className={classes.navItem}
          >
            <div style={{marginBottom: "5px"}}>
              Actualités
            </div>
          <div className={activItem === "/actualites" ? "activ-underline" : "underline"}></div>
      </Link> */}
      <Link
            to="/contact"
            className={classes.navItem}
          >
            <div style={{marginBottom: "5px"}}>
              Contact
            </div>
          <div className={activItem === "/contact" ? "activ-underline" : "underline"}></div>
      </Link>
    </div>
  </header>
)}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header
