import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
	const { site } = useStaticQuery(
		graphql`
		query {
			site {
				siteMetadata {
					title
					description
					author
				}
			}
		}
		`
	)

	const metaDescription = description || site.siteMetadata.description
	const defaultTitle = site.siteMetadata?.title

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata?.author || ``,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
			].concat(meta)}
		/>
	)
}

SEO.defaultProps = {
	lang: `fr`,
	meta: [],
	description: `Le cabinet intervient principalement en droit public, en droit de l’urbanisme, en droit de l'environnement et en matière de propriété intellectuelle. Il intervient également sur tous les aspects européens et internationaux des dossiers. Constitué sous la forme d’une AARPI dirigée par deux avocats associés, le cabinet ESSERE AVOCATS offre à sa clientèle un haut niveau de compétence, une réactivité et une disponibilité sur les dossiers qui lui sont confiés. Les avocats du cabinet exercent une activité de conseil et de contentieux`,
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
}

export default SEO
