import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import DrawerMenu from './drawer';
import Header from "./header"
import Footer from "./footer"
import "./layout.css"

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
		query {
			image: file(relativePath: { eq: "logo_essere.png" }) {
				childImageSharp {
					fluid(maxWidth: 300) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)

	const [state, setState] = React.useState({
		drawerOpen: false
	});

	React.useEffect(() => {
		window.onresize = () => {
			if (window.innerWidth >= 960) {
				setState({ drawerOpen: false });
			}
		}
	}, [])

	const toggleDrawer = () => {
		setState({ drawerOpen: !state.drawerOpen });
	};

	return (
		<>
			<DrawerMenu isOpen={state.drawerOpen} toggleDrawer={toggleDrawer} />
			<Header toggleDrawer={toggleDrawer} image={data.image.childImageSharp.fluid} />
			<div id="layout-wrapper">
				<main>{children}</main>
			</div>
			<Footer image={data.image.childImageSharp.fluid} />
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
