import React from 'react'
import { Link } from "gatsby"
import Img from "gatsby-image"
import Grid from "@material-ui/core/Grid"

const Footer = ({image}) => {

	return (
		<footer id="footer">
			<Grid id="footer-wrapper" container>
				<Grid className="footer-item" item xs={12} sm={5}>
				<div className="logo-wrapper">
					<Link to="/" >
						<Img fluid={image} />
					</Link>
				</div>
				<p className="footer-margin">Cabinet d’avocats publicistes situé à Marseille et à Bandol</p>
				<div className="footer-margin">
					<p className="footer-name">&gt; Maître Jean François Marchi</p>
					<p className="footer-name">&gt; Maître Ornella Ricciotti</p>
				</div>
				</Grid>
				<Grid id="footer-link" className="footer-item" item xs={12} sm={4}>
					<Link className="footer-link" to="/" >
						Le Cabinet
					</Link>
					<Link className="footer-link"  to="/domaines" >
						Nos domaines
					</Link>
					<Link className="footer-link"  to="/equipe" >
						L'équipe
					</Link>
					<Link className="footer-link"  to="/contact" >
						Contact
					</Link>
					{/* <Link className="footer-link"  to="/mentions-legales" >
						Mentions Legales
					</Link> */}
				</Grid>
				<Grid className="footer-item" item xs={12} sm={3}>
					<div style={{marginBottom: "1rem"}}>
						<a className="footer-link" href="https://www.google.com/maps/place/ESSERE+AVOCATS/@43.2906421,5.3773453,15z/data=!4m5!3m4!1s0x0:0xdb0379a797d3fdef!8m2!3d43.2906421!4d5.3773453" target="_blank" rel="noopener noreferrer">6 Cours Pierre Puget<br />13006 - MARSEILLE</a>
						<div className="activ-underline margin-underline"></div>
						<a className="footer-link" href="https://www.google.com/maps/place/Cabinet+ESSERE+AVOCATS/@43.1364264,5.752204,17z/data=!3m1!4b1!4m5!3m4!1s0x12c9071270a40001:0x2f4ae0d21e15fa25!8m2!3d43.1364264!4d5.7543927" target="_blank" rel="noopener noreferrer">3 Place d'estienne d'orves<br />83150 - BANDOL</a>
					</div>
					<div>
						<a className="footer-link" href="tel:+33484258671">Tél: 04.84.25.86.71</a>
						<p className="footer-link">Fax: 04.84.25.86.70</p>
						<a className="footer-link" href="mailto:contact@essere-avocats.com">Email: contact@essere-avocats.com</a>
					</div>
				</Grid>
			</Grid>
			<div id="copyright">
				<p className="copyright-link">Copyright <a className="copyright-link" href="https://www.noun-graphisme.fr/" target="_blank" rel="noopener noreferrer">&copy;Noun-Graphisme.fr</a> / <a className="copyright-link" href="https://www.the-web-kitchen.fr/" target="_blank" rel="noopener noreferrer">&copy;The-web-kitchen.fr</a></p>
			</div>
		</footer>
	)
}

export default Footer